
.fa-asterisk {
  font-size: 0.75em;
}

table .text-right:after {
  content: ':';
}

.page-list {
  .page-list-entry > div {
    padding: 5px 0;
    border: 1px solid #CCC;
    margin: 5px 0;
  }

  i {
    cursor: pointer;
  }

  .active {
    color: red;
  }
}

.header-row .modal-title {
  display: block;
  width: 100%;
}

.table th {
  vertical-align: middle;
}

.product-list {
  margin: 0;
  padding: 0;
}

.brand-image {
  height: 40px;
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

.ck-editor__editable_inline {
  min-height: 100px;
}


.min-wrap{
  width: 1%;
  text-wrap: none;
}

.color {
  width: 20px;
  height: 20px;
  border: solid 2px #495057;
  vertical-align: middle;
  border-radius: 50%;
  display: inline-block;
}

